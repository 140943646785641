
import { defineComponent } from "vue";
import AudioComponent from "@/components/AudioComponent.vue";
import AutreDataService from "@/services/AutreDataService";
import Autre from "@/types/Autre";
import ResponseData from "@/types/ResponseData";

export default defineComponent({
  name: "Autres",
  components: { AudioComponent },
  data() {
    return {
      autres: [] as Autre[],
    };
  },
  methods: {
    retrieveAutres() {
      AutreDataService.getAll()
        .then((response: ResponseData) => {
          this.autres = response.data;
        })
        .catch((e: Error) => {
          // eslint-disable-next-line
          console.log(e);
        });
    },
    clickOnExpandButton(autre: Autre) {
      autre.isExpanded = true;
    },
    clickOnCollapseButton(autre: Autre) {
      autre.isExpanded = false;
    },
  },
  mounted() {
    this.retrieveAutres();
  },
});
