import axios from "axios";
import Autre from "@/types/Autre";

class AutreDataService {
  // eslint-disable-next-line
  getAll(): Promise<any> {
    return axios
      .get('https://gitlab.com/api/v4/projects/33474676/repository/files/autres_compositions.csv/raw')
      .then((response) => {
        const autresResults = [] as Autre[];

        const csvString = response.data;
        const delimiter = ";";

        const rows = csvString.slice(csvString.indexOf("\n") + 1).split("\n");
        rows.pop();

        rows.map(function (row: string) {
          const values = row.split(delimiter);

          const gitlabRepositoryAbsolutePath = 'https://gitlab.com/psautier-de-sainte-lucie/psautier-donnees/-/raw/main';
          const filename = values[0];
          const title = values[1];
          const numberOfPages = Number(values[2]);

          const pngAbsolutePath = [''];
          for (let index = 0; index < numberOfPages; index++) {
            pngAbsolutePath[index] = gitlabRepositoryAbsolutePath + '/autres-png/' + filename + '-' + (index + 1) + '.png';
          }

          const pdfAbsolutePath = gitlabRepositoryAbsolutePath + '/autres-pdf-mscz/' + filename + '.pdf';
          const msczAbsolutePath = gitlabRepositoryAbsolutePath + '/autres-pdf-mscz/' + filename + '.mscz';
          const hasPolyphonieAudioFile = values[3].toLowerCase() == 'true';
          const polyphonieAbsolutePath = gitlabRepositoryAbsolutePath + '/autres-audio/' + filename + '-p' + '.mp3';
          const hasSopranoAudioFile = values[4].toLowerCase() == 'true';
          const sopranoAbsolutePath = gitlabRepositoryAbsolutePath + '/autres-audio/' + filename + '-s' + '.mp3';
          const hasAltoAudioFile = values[5].toLowerCase() == 'true';
          const altoAbsolutePath = gitlabRepositoryAbsolutePath + '/autres-audio/' + filename + '-a' + '.mp3';
          const hasTenorAudioFile = values[6].toLowerCase() == 'true';
          const tenorAbsolutePath = gitlabRepositoryAbsolutePath + '/autres-audio/' + filename + '-t' + '.mp3';
          const hasBasseAudioFile = values[7].toLowerCase() == 'true';
          const basseAbsolutePath = gitlabRepositoryAbsolutePath + '/autres-audio/' + filename + '-b' + '.mp3';
          const isExpanded = false;

          const newAutre = {
            filename,
            title,
            numberOfPages,
            pngAbsolutePath,
            pdfAbsolutePath,
            msczAbsolutePath,
            hasPolyphonieAudioFile,
            polyphonieAbsolutePath,
            hasSopranoAudioFile,
            sopranoAbsolutePath,
            hasAltoAudioFile,
            altoAbsolutePath,
            hasTenorAudioFile,
            tenorAbsolutePath,
            hasBasseAudioFile,
            basseAbsolutePath,
            isExpanded,
          } as Autre;
          autresResults.push(newAutre);
        });

        return { data: autresResults };
      })
      // eslint-disable-next-line
      .catch(error => console.log(error));
  }
}

export default new AutreDataService();
